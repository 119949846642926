import Link from "next/link"

export default function Home(props) {
    
    const {
        data
    } = props || {}
    
    const {
        projects,
        introduction
    } = data || {}

    return (
        <div className="relative flex items-center justify-center h-screen">

            <p className="text-center leading-none font-light">
                <span className="text-orange-500 block text-[20vw]">404</span>
                <span className="text-white block">page not found. <Link href="/"><a className="underline transition-colors hover:text-orange-500">Back to safety</a></Link>.</span>
            </p>

        </div>
    )
    }